import { Box } from "grommet";
import React from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/elements/layout";
import LoadingAnimation from "../components/elements/loading_animation";
import storageService from "../services/storage_service";
import authService from '../services/auth_service'
import partnerService from '../services/partner_service';
class UpdatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = async () => {
    authService.getVersion().then(async (res) => {
      storageService.setVersion(res.data.version);
      await partnerService.ping();
      setTimeout(() => {
        if(typeof window === 'undefined') return;
        window.location.href = '/';
      }, 1000);
    }).catch(err => {
      setTimeout(() => {
        if(typeof window === 'undefined') return;
        window.location.href = '/';
      }, 1000);
    });
  };

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Layout style={{ justifyContent: "center", alignContent: "center" }}>
          <Box
            width="full"
            justify="center"
            align="center"
            alignSelf="center"
            overflow={{ vertical: "auto" }}
            style={{ minHeight: "auto" }}
          >
            <LoadingAnimation />
          </Box>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Layout>
      </BlockUi>
    );
  }
}

export default UpdatePage;
